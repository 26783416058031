import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Layout from '../../layouts';
import Calendly from 'pourmgmt/calendly';

export default (props) => (
  <Layout>
    <Row>
    <Col>
      <div className="about-content about-read" style={{textAlign: 'center', marginBottom: '30px'}}>
        <Calendly account="iconicwine" eventName="virtual-tasting-and-chat"/>
      </div>
    </Col>
    </Row>
    </Layout>
)
